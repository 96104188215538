import { defineStore } from 'pinia';
import Config from '@/config';
import { createRequestData, performHttpRequest } from '@/utilities';
import { createNotification } from '@/stores/user/notifications';
import { NOTIFICATION_TYPE } from '@/types/user';
import { RespError } from '@/types/general';
import {
  ExchangeServerResp, AccountServerResp, AccountType, ApiKeyType,
  ApiKeyTypeWrapper, AccountManagementState, ValidExchangeNamesServerResp, AccountClientReq, ExchangeNameTypes,
  SubExchangeServerResp,
} from '@/types/account';
import { ENTITY_CATEGORY, EntityType, SubEntityType } from '@/types/exchange';
import { WalletType } from '@/types/wallets';
import { useClientLogsStore } from '@/stores/user/clientLogs';


export const useAccountManagementStore = defineStore('accountManagement', {
  state: (): AccountManagementState => ({
    exchanges: {},
    validExchangeNames: {},
  }),
  actions: {
    setApiKey({ exchangeName, accountId, apiKey }: ApiKeyTypeWrapper) {
      const newApiKey = new ApiKeyType(apiKey.key, apiKey.secret, apiKey.name, apiKey.passphrase, apiKey.createdTs);
      this.exchanges[exchangeName].accounts[accountId].apiKey = newApiKey;
    },
    setExchange(body: ExchangeServerResp) {
      if (body.exchange.name in this.exchanges) {
        return;
      }

      if (!(Object.values(ENTITY_CATEGORY).includes(body.exchange.category as ENTITY_CATEGORY))) {
        return;
      }

      this.exchanges[body.exchange.name] = new EntityType(
        body.exchange.name,
        body.exchange.category as ENTITY_CATEGORY,
        body.exchange.custom,
      );
    },
    addSubExchange(body: SubExchangeServerResp) {
      const exchangeName = body.exchangeName;
      const subExchangeType = body.subExchange.exchangeType;

      if (!(exchangeName in this.exchanges)) {
        useClientLogsStore().errorLog(
          `[${exchangeName}][${subExchangeType}] Failed to add sub exchange (exchange was not set)`,
        );
        return;
      }

      const exchange = this.exchanges[exchangeName];

      if (subExchangeType in exchange.subExchanges) {
        useClientLogsStore().errorLog(
          `[${exchangeName}][${subExchangeType}] Failed to add sub exchange (sub exchange was already set)`,
        );
        return;
      }

      exchange.subExchanges[subExchangeType] = new SubEntityType(subExchangeType);
    },
    setAccount(body: AccountServerResp) {
      const newAccount = new AccountType(body.account.id, body.account.name, body.account.type);
      this.exchanges[body.exchangeName].accounts[body.account.id] = newAccount;

      if (body.account.apiKey) {
        const apiKey = new ApiKeyType('', '', '', '', body.account.apiKey.createdTs);
        this.setApiKey({
          exchangeName: body.exchangeName,
          accountId: body.account.id,
          apiKey: apiKey,
        });
      }
    },
    setValidExchangeNames({ data }: ValidExchangeNamesServerResp) {
      this.validExchangeNames = data;
    },
    addEntity(body: { userToken: string, exchange: EntityType }): Promise<string> {
      const requestInfo = createRequestData('POST', body.userToken, body.exchange);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges`,
        requestInfo,
        'create',
        'exchange',
      );
    },
    addEntityWallet(
      userToken: string, exchangeName: string, accountId: string, wallet: WalletType,
    ): Promise<string> {
      const requestInfo = createRequestData('POST', userToken, wallet);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${exchangeName}/accounts/${accountId}/wallets`,
        requestInfo,
        'create',
        'wallet',
      );
    },
    updateEntityWallet(
      userToken: string, exchangeName: string, accountId: string, wallet: WalletType,
    ): Promise<string> {
      const requestInfo = createRequestData('PATCH', userToken, wallet);

      return performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${exchangeName}/accounts/${accountId}/wallets/${wallet.id}`,
        requestInfo,
        'update',
        'wallet',
      );
    },
    deleteEntityWallet(userToken: string, exchangeName: string, accountId: string, wallet: WalletType) {
      const requestInfo = createRequestData('DELETE', userToken, wallet);

      void performHttpRequest(
        `${Config.apiEndpoint()}/exchanges/${exchangeName}/accounts/${accountId}/wallets/${wallet.id}`,
        requestInfo,
        'delete',
        'wallet',
      );
    },
    // TODO deleting exchanges is currently removed because can't delete exchange without affecting test and main net
    // async deleteExchange(body: any) {
    // const requestInfo = DELETE.createRequestData('DELETE', body.userToken, body.exchange);
    //   const resp = await fetch(`${Config.apiEndpoint()}/exchanges`, requestInfo);
    //   const jsonResp = await resp.json();
    //   let type, msg;

    //   if (resp.status == 200) {
    // delete this.exchanges[body.exchange.name];
    //     type = NOTIFICATION_TYPE.SUCCESS;
    //     msg = `Exchange ${body.exchange.name} successfully deleted`;
    //   } else {
    //     type = NOTIFICATION_TYPE.ERROR;
    //     msg = `Exchange ${body.exchange.name} failed to delete, error: ${jsonResp.error}`
    //   }

    // notificationsStore.addNotification({
    //     "type": type,
    //     "message": msg,
    // });
    //   return resp;
    // },
    async addAccount(body: AccountClientReq) {
      const requestInfo = createRequestData('POST', body.userToken, body.account);

      try {
        const resp = await fetch(`${Config.apiEndpoint()}/exchanges/${body.exchangeName}/accounts`, requestInfo);
        const jsonResp = await resp.json() as RespError | AccountType;

        if (resp.status == 200) {
          this.setAccount({ exchangeName: body.exchangeName, account: jsonResp as AccountType });
          const msg = `Account ${body.exchangeName} ${body.account.name} successfully added`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const typedResp = jsonResp as RespError;
          const msg = `Account ${body.exchangeName} ${body.account.name} failed to create, error: ${typedResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async updateAccount(body: AccountClientReq) {
      const requestInfo = createRequestData('PATCH', body.userToken, body.account);

      try {
        const resp = await fetch(
          `${Config.apiEndpoint()}/exchanges/${body.exchangeName}/accounts/${body.accountId}`, requestInfo);
        const jsonResp = await resp.json() as RespError | AccountType;
        let msg;

        if (resp.status == 200) {
          const typedResp = jsonResp as AccountType;
          const exchangeName = body.exchangeName,
            accountId = typedResp.id,
            accountName = typedResp.name;

          this.exchanges[exchangeName].accounts[accountId].name = accountName;
          this.exchanges[exchangeName].accounts[accountId].type = body.account.type;

          msg = `Account ${body.exchangeName} ${body.account.name} successfully updated`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const typedResp = jsonResp as RespError;
          msg = `Account ${body.exchangeName} ${body.account.name} failed to update, error: ${typedResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
    async deleteAccount(body: AccountClientReq) {
      const requestInfo = createRequestData('DELETE', body.userToken, body.account);

      try {
        const resp = await fetch(`${Config.apiEndpoint()}/exchanges/${body.exchangeName}/accounts`, requestInfo);
        const jsonResp = await resp.json() as RespError;

        if (resp.status == 200) {
          delete this.exchanges[body.exchangeName].accounts[body.account.id];
          const msg = `Account ${body.exchangeName} ${body.account.id} successfully deleted`;
          createNotification(msg, NOTIFICATION_TYPE.SUCCESS);
          return resp;
        } else {
          const msg = `Account ${body.exchangeName} ${body.account.id} failed to delete, error: ${jsonResp.error}`;
          throw new Error(msg);
        }
      } catch (error: unknown) {
        const typedError = error as RespError;
        createNotification(typedError.message, NOTIFICATION_TYPE.ERROR);
        return Promise.reject(typedError);
      }
    },
  },
  getters: {
    getValidExchangeNames: (state): Record<string, ExchangeNameTypes> => state.validExchangeNames,
    getOrderedExchangesNames: (state): string[] => Object.keys(state.exchanges).sort(),
    getExchanges: (state): Record<string, EntityType> => state.exchanges,
    getExchange: (state) => (exchangeName: string): EntityType => {
      return exchangeName in state.exchanges ? state.exchanges[exchangeName] : null;
    },
    getAccounts: (state) => (exchangeName: string): Record<string, AccountType> => {
      if (exchangeName === '') {
        let accounts: Record<string, AccountType> = {};

        for (exchangeName in state.exchanges) {
          accounts = {
            ...accounts,
            ...state.exchanges[exchangeName].accounts,
          };
        }

        return accounts;
      }

      if (exchangeName in state.exchanges) {
        return state.exchanges[exchangeName].accounts;
      }

      return null;
    },
    getAccount: (state) => (exchangeName: string, accountId: string): AccountType => {
      if (exchangeName in state.exchanges && accountId in state.exchanges[exchangeName].accounts) {
        return state.exchanges[exchangeName].accounts[accountId];
      }

      return null;
    },
  },
});
