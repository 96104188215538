<template>
  <div class="panel">
    <div class="panel__header" :class="{ 'panel__header--active': !collasped }">
      <h3 class="panel__header__title"> {{ props.title }} - {{ ordersView?.length }}</h3>
      <div class="panel__header__button panel__header__button--last">
        <router-link v-if="Object.keys($route.query).length > 0" class="button" :to="{ path: $route.fullPath }">
          Clear Filters
        </router-link>
      </div>
      <i
        :class="{ 'ti-angle-up': !collasped, 'ti-angle-down': collasped }"
        class="panel__header__icon" @click="collasped = !collasped"
      />
    </div>

    <div v-if="!collasped" class="panel__body vtable">
      <div class="vtable-header hand" style="padding-left: 4px;" @click="updateTableData($event)">
        <div class="vtable-header__item vtable__size--m-25 vtable__size--8" name="exchangeName">
          <select v-model="exchangeNameFilter" name="exchangeNameFilter" class="vtable-header__item__input">
            <option value="">EX. NAME</option>
            <option value="" disabled>--</option>
            <option v-for="(exchangeName, id) in orderedExchangeNames" :key="id">{{ exchangeName }}</option>
          </select>
        </div>
        <div class="vtable-header__item vtable__size--m-37 vtable__size--10" name="symbol">
          <div class="vtable-header__input-wrap">
            <input
              v-model="symbolFilterInputFieldOnly" class="vtable-header__item__input-wrap__input"
              type="text" placeholder="SYMBOL NAME" autocomplete="off" @input="debouncedSymbolSearch($event)"
            />
            <i class="ti-arrow-down sort-icon" />
          </div>
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--11" name="price" title="Execution Price">
          Order Price <i class="ti-arrow-down sort-icon" />
        </div>

        <div
          ref="defaultSortByTH"
          class="vtable-header__item vtable__size--m-hide vtable__size--13"
          name="distanceLP"
          title="Distance: LP% (MP%)"
        >
          DIST. LP% (MP%) <i class="ti-arrow-down sort-icon" />
        </div>

        <div class="vtable-header__item vtable__size--m-hide vtable__size--13" name="lastPrice">
          Price: LP (MP) <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--12" name="quantity" title="Quantity">
          Qty. (shown) <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--5" name="leverage" title="Leverage">
          Lev. <i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--9" name="margin">
          <select v-model="marginFilter" class="vtable-header__item__input" name="">
            <option value="">MARGIN</option>
            <option value="" disabled>--</option>
            <option v-for="asset in assets" :key="asset">{{ asset }}</option>
          </select>
          <i class="ti-arrow-down sort-icon" style="padding-left: 6px;" name="marginFilter" />
        </div>
        <div class="vtable-header__item vtable__size--m-hide vtable__size--7" name="chosenAssetProfit">
          Profit ({{ choosenAssetSymbol }})<i class="ti-arrow-down sort-icon" />
        </div>
        <div class="vtable-header__item vtable__size--m-8 vtable__size--d-hide" />
      </div>
      <DynamicScroller
        :items="ordersView" :min-item-size="36"
        :class="$route.name != 'symbol' ? 'panel__body__table--large' : ''"
      >
        <template #default="{ item, index, active }">
          <DynamicScrollerItem
            :item="item" :active="active" :size-dependencies="[item.showOverview]" :data-index="index"
          >
            <div
              class="vtable__row"
              :class="item.showOverview ?
                'vtable__row--'+item.order.side+ ' vtable__row--overview-active' :
                'vtable__row--'+item.order.side"
              :title="item.order.id"
              :data-testid="item.order.id"
              @click="selectOrder($event, item.order.id)"
            >
              <div data-testid="exchange" class="vtable__row__item vtable__size--m-25 vtable__size--8">
                {{ item.order.exchangeName }}
                ({{ item.order.exchangeType === EXCHANGE_TYPE.DERIVATIVES ? 'D' : 'S' }})
              </div>
              <div class="vtable__row__item vtable__size--m-37 vtable__size--10">
                <router-link
                  :title="item.order.symbol"
                  :to="{
                    path: `/exchanges/${item.order.exchangeName}/${item.order.exchangeType}/` +
                      `${encodeURIComponent(item.order.symbol)}/${item.accountId}`,
                    query: { 'selectedOrder': item.order.id, }
                  }"
                >
                  {{ item.consistentSymbol }}
                </router-link>
              </div>
              <div data-testid="price" class="vtable__row__item vtable__size--m-hide vtable__size--11">
                {{ formatNumber(item.order.price) }}
              </div>
              <div data-testid="distanceLP" class="vtable__row__item vtable__size--m-30 vtable__size--13">
                {{ item.distanceLP }}%<span v-if="item.distanceMP">&nbsp;({{ item.distanceMP }}%)</span>
              </div>
              <div data-testid="lastPrice" class="vtable__row__item vtable__size--m-hide vtable__size--13">
                {{ formatNumber(item.lastPrice) }}
                <span v-if="parseFloat(item.markPrice)">&nbsp;({{ formatNumber(item.markPrice) }})</span>
              </div>
              <div data-testid="quantity" class="vtable__row__item vtable__size--m-hide vtable__size--12">
                {{ formatNumber(item.order.quantity) }}
              </div>
              <div data-testid="leverage" class="vtable__row__item vtable__size--m-hide vtable__size--5">
                {{ item.leverage }}x
              </div>
              <div data-testid="margin" class="vtable__row__item vtable__size--m-hide vtable__size--9">
                {{ formatNumber(item.order.margin) }}
              </div>
              <div data-testid="profit" class="vtable__row__item vtable__size--m-hide vtable__size--7">
                {{ formatNumber(item.chosenAssetProfit) }}
              </div>

              <div class="vtable__row__item vtable__row__item--open-btn vtable__size--d-hide vtable__size--m-8">
                <button class="vtable__row__button" @click="item.showOverview = !item.showOverview">
                  <i :class="!item.showOverview ? 'ti-angle-down' : 'ti-angle-up'" />
                </button>
              </div>
              <div v-if="item.showOverview" class="vtable__row__overview">
                <div class="vtable__m-overview">
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Distance: LP% (MP%)</label>
                    <div>{{ item.distanceLP }}%<span v-if="item.distanceMP"> ({{ item.distanceMP }}%)</span></div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Order Price</label>
                    <div>{{ formatNumber(item.order.price) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Liquidation Price</label>
                    <div>{{ formatNumber(item.order.liquidation) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Price: LP (MP)</label>
                    <div>
                      {{ formatNumber(item.lastPrice) }}
                      <span v-if="parseFloat(item.markPrice)"> ({{ formatNumber(item.markPrice) }})</span>
                    </div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Quantity</label>
                    <div>{{ formatNumber(item.order.quantity) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Leverage</label>
                    <div>{{ item.leverage }}x</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Margin</label>
                    <div>{{ formatNumber(item.order.margin) }}</div>
                  </div>
                  <div class="vtable__m-overview__item">
                    <label style="text-transform: uppercase;">Entered</label>
                    <div>{{ convertUnixTimestampToDate(item.order.created) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useInstrumentsStore } from '@/stores/exchanges/instruments';
import { usePricesStore } from '@/stores/exchanges/prices';
import { useOrdersStore } from '@/stores/exchanges/orders';
import { useUserSettingsStore, forexAssets, indexAssets } from '@/stores/user/settings';
import { useQueryStringStore } from '@/stores/queryString';
import { useAccountManagementStore } from '@/stores/exchanges/accountManagement';
import { useRoute } from 'vue-router';
import { formatNumber, calculateProfit, getDistance } from '@/utilities';
import { SIDE } from '@/types/account';
import { EXCHANGE_TYPE } from '@/types/exchange';
import { OrderType, OrderTypeKeys, OrderProfitType } from '@/types/orders';

class OrderRowView {
  public id: string; // For the virtual scroller (order ID)
  public order: OrderType;
  public lastPrice: string;
  public markPrice: string;
  public margin: string;
  public chosenAssetProfit: string;
  public distanceLP: number;
  public distanceMP: number;
  public consistentSymbol: string;
  public leverage: string;
  public exchangeName: string;
  public accountId: string;

  constructor(order: OrderType) {
    this.id = order.id;
    this.order = order;
  }
}

// Store
const userSettingsStore = useUserSettingsStore();
const accountManagementStore = useAccountManagementStore();
const instrumentsStore = useInstrumentsStore();
const pricesStore = usePricesStore();
const ordersStore = useOrdersStore();
const queryStringStore = useQueryStringStore();

const route = useRoute();

const emit = defineEmits(['selectedOrders']);

// Computed
const instruments = computed(() => instrumentsStore.instruments);
const prices = computed(() => pricesStore.prices);
const orderedExchangeNames = computed(() => accountManagementStore.getOrderedExchangesNames);
const choosenAssetSymbol = computed(() => userSettingsStore.getChosenAssetSymbol);

// Parent properties
const props = withDefaults(defineProps<{
  title: string,
}>(), {
  title: '',
});

// Variables
const sortByTH = ref<HTMLElement>(null);
const defaultSortByTH = ref<HTMLElement>(null);
const orderBy = ref('asc');
const collasped = ref(false);
const marginFilter = ref(''); // TODO Watch this to update URL params
const exchangeNameFilter = ref(''); // TODO Watch this to update URL params
const exchangeTypeFilter = ref('');
const sideFilter = ref('');
const symbolFilter = ref('');
const symbolFilterInputFieldOnly = ref('');
const showForexOrders = ref(true);
const showIndicesOrders = ref(true);
const showOtherOrders = ref(true);
const timeout = ref<number | null>(null);
const assets = ref<string[]>([]);
const sortBy = ref<OrderTypeKeys | keyof OrderRowView>('distanceLP');
const ordersView = ref<OrderRowView[]>([]);

// Watchers
watch(() => ordersStore.exchanges, () => {
  computeOrdersData();
}, { deep: true });

watch(exchangeNameFilter, (val: string) => {
  if (val !== route.query.exchangeName as string) {
    queryStringStore.update({ 'exchangeName': val || null });
  }
});

watch(exchangeTypeFilter, (val: string) => {
  if (val !== route.query.exchangeType as string) {
    queryStringStore.update({ 'exchangeType': val || null });
  }
});

watch(marginFilter, (val: string) => {
  if (val !== route.query.margin as string) {
    queryStringStore.update({ 'margin': val || null });
  }
});

watch(() => String(route.query.exchangeName || ''), (val: string) => {
  exchangeNameFilter.value = val || '';
  computeOrdersData();
}, { immediate: false });

watch(() => String(route.query.exchangeType || ''), (val: string) => {
  exchangeTypeFilter.value = val;
  computeOrdersData();
}, { immediate: false });

watch(() => String(route.query.symbol || ''), (val: string) => {
  symbolFilter.value = val;
  computeOrdersData();
}, { immediate: false });

watch(() => route.query.margin, () => {
  computeOrdersData();
}, { immediate: false });

watch(() => route.query.side ? String(route.query.side) : '', (val: string) => {
  sideFilter.value = val || '';
  computeOrdersData();
}, { immediate: false });

watch(() => String(route.query.hideForexOrders) == 'true' , (val: boolean) => {
  showForexOrders.value = !val;
  computeOrdersData();
}, { immediate: false });

watch(() => String(route.query.hideIndicesOrders) == 'true', (val: boolean) => {
  showIndicesOrders.value = !val;
  computeOrdersData();
}, { immediate: false });

watch(() => String(route.query.hideOtherOrders) == 'true', (val: boolean) => {
  showOtherOrders.value = !val;
  computeOrdersData();
}, { immediate: false });

// Vue Lifecycle Functions
onMounted(() => {
  exchangeNameFilter.value = route.query.exchangeName as string || '' ;
  exchangeTypeFilter.value = route.query.exchangeType as string || '' ;
  sideFilter.value = route.query.side as string || '' ;
  symbolFilter.value = route.query.symbol as string || '' ;
  symbolFilterInputFieldOnly.value = route.query.symbol as string || '' ;
  marginFilter.value = route.query.margin as string || '' ;

  sortByTH.value = defaultSortByTH.value;

  computeOrdersData();
});

// Functions
const debouncedSymbolSearch = (event: Event) => {
  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = window.setTimeout(() => {
    const target = event.target as HTMLInputElement;
    if (target.value !== route.query.symbol as string) {
      queryStringStore.update({ 'symbol': target.value || '' });
    }
  }, 500);
};

const updateTableData = (e: Event) => {
  let target = e.target as HTMLElement;

  if (target.classList.contains('vtable-header__input-wrap__input')) {
    return;
  }
  if (target.classList.contains('sort-icon')) {
    target = target.parentNode as HTMLElement;
  }
  if (target.classList.contains('vtable-header__input-wrap')) {
    target = target.parentNode as HTMLElement;
  }

  const name = target.getAttribute('name');

  if (!name || name === 'exchangeNameFilter') {
    return;
  }

  if (name === sortBy.value) {
    // Update orderBy
    const lastOrderBy = orderBy.value;
    orderBy.value = orderBy.value === 'asc' ? 'desc' : 'asc';

    if (sortByTH.value) {
      sortByTH.value.classList.remove(lastOrderBy);
      sortByTH.value.classList.add(orderBy.value);
    }
  } else {
    // Update sortBy
    sortBy.value = name as OrderTypeKeys;

    if (sortByTH.value) {
      sortByTH.value.classList.remove('desc');
      sortByTH.value.classList.remove('asc');
    }

    sortByTH.value = target;
    orderBy.value = 'asc';
    sortByTH.value.classList.add('asc');
  }
  computeOrdersData();
};

const convertUnixTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000),
    year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hours = date.getHours(),
    minutes = `0${date.getMinutes()}`,
    seconds = `0${date.getSeconds()}`,
    formattedDate = `${day}/${month}/${year} ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

  return formattedDate;
};

const calculatePNL = (orderView: OrderRowView): string => {
  const instrument = instruments.value?.[orderView.order.exchangeName]?.[orderView.order.exchangeType]?.
    [orderView.order.symbol];
  const profitData = new OrderProfitType(
    orderView.order.exchangeType,
    orderView.order.exchangeName,
    instrument,
    Number(orderView.order.price),
    Number(orderView.lastPrice),
    Math.abs(Number(orderView.order.quantity)),
  );
  const profit = calculateProfit(profitData);

  if (orderView.order.side === SIDE.SELL && profitData.limitPrice < profitData.lastPrice) {
    return `-${profit}`;
  }

  if (orderView.order.side == SIDE.BUY && profitData.limitPrice > profitData.lastPrice) {
    return `-${profit}`;
  }

  return profit;
};

const computeOrdersData = () => {
  const newOrdersView: OrderRowView[] = [];
  const exchangeNamesMap: Record<string, boolean> = {};
  const assetsMap: Record<string, boolean> = {};

  for (const exchangeName in ordersStore.exchanges) {
    const accounts = ordersStore.exchanges[exchangeName].accounts;

    exchangeNamesMap[exchangeName] = true;

    if (exchangeNameFilter.value !== '') {
      if (exchangeName !== exchangeNameFilter.value) {
        continue;
      }
    }

    for (const accountId in accounts) {
      const account = accounts[accountId];

      for (const orderId in account.orders) {
        const order = account.orders[orderId];

        assetsMap[order.marginAsset] = true;

        if (exchangeTypeFilter.value !== '') {
          if (order.exchangeType !== exchangeTypeFilter.value) {
            continue;
          }
        }

        if (marginFilter.value !== '') {
          if (order.marginAsset !== marginFilter.value) {
            continue;
          }
        }

        if (sideFilter.value !== '') {
          if (order.side !== sideFilter.value) {
            continue;
          }
        }

        const instrument = instruments.value?.[exchangeName]?.[order.exchangeType]?.[order.symbol];
        let consistentSymbol = '';

        if (instrument) {
          consistentSymbol = instrument.consistentSymbol;
        } else {
          // console.log(
          //   `UNKNOWN: state.instruments[${exchangeName}][${exchangeType}][${symbol}]`,
          //   this.instruments?.[exchangeName]?.[exchangeType],
          // );
          consistentSymbol = order.symbol;
        }

        if (symbolFilter.value !== '') {
          if (consistentSymbol.toLowerCase().indexOf(symbolFilter.value.toLowerCase()) === -1) {
            continue;
          }
        }

        const [baseAsset, quoteAsset] = consistentSymbol.split('-') || ['', ''];
        let assetType = 'other';

        if (forexAssets.includes(baseAsset) && forexAssets.includes(quoteAsset)) {
          assetType = 'forex';
        } else if (indexAssets.includes(baseAsset)) {
          assetType = 'index';
        }

        if (!showForexOrders.value && assetType === 'forex') {
          continue;
        }

        if (!showIndicesOrders.value && assetType === 'index') {
          continue;
        }

        if (!showOtherOrders.value && assetType === 'other') {
          continue;
        }

        const orderView = new OrderRowView(order);

        orderView.exchangeName = exchangeName;
        orderView.accountId = accountId;
        orderView.consistentSymbol = consistentSymbol;
        orderView.margin = order.margin;

        const price = prices.value?.[exchangeName]?.[order.exchangeType]?.[order.symbol];

        if (price) {
          orderView.lastPrice = price.lastPrice;
          orderView.markPrice = price.markPrice;
          orderView.distanceLP = getDistance(order.price, orderView.lastPrice, 0);
          orderView.distanceMP = getDistance(order.price, orderView.markPrice, 0);
        }

        if (order.leverage === '0') { // This means the leverage is with the instrument (derivatives only, e.g. Bitmex)
          if (instrument) {
            orderView.leverage = instrument.leverage;
          } else {
            orderView.leverage = '0';
          }
        } else {
          orderView.leverage = order.leverage;
        }

        orderView.chosenAssetProfit = calculatePNL(orderView);
        newOrdersView.push(orderView);
      }
    }
  }

  assets.value = Object.keys(assetsMap).sort((a, b) => a.localeCompare(b));

  newOrdersView.sort(
    (a, b) => {
      const v1U = sortBy.value in a ? a[sortBy.value as keyof OrderRowView] : a.order[sortBy.value as OrderTypeKeys];
      const v2U = sortBy.value in b ? b[sortBy.value as keyof OrderRowView] : b.order[sortBy.value as OrderTypeKeys];
      const v1 = isNaN(Number(v1U)) ? String(v1U) : Number(v1U);
      const v2 = isNaN(Number(v2U)) ? String(v2U) : Number(v2U);
      const expression = orderBy.value === 'asc' ? v1 > v2 ? 1 : (v2 > v1 ? -1 : 0) : v1 < v2 ? 1 : (v2 < v1 ? -1 : 0);

      return expression;
    },
  );

  ordersView.value = newOrdersView;

  const selectedOrders: Record<string, OrderType> = {};

  newOrdersView.forEach(orderView => {
    // Still only filter out orders that are relevant to this pair
    if (orderView.exchangeName !== route.params.exchangeName ||
        orderView.order.exchangeType !== route.params.exchangeType ||
        orderView.order.symbol !== route.params.symbol) {
      return;
    }

    selectedOrders[orderView.order.id] = orderView.order;
  });

  emit('selectedOrders', selectedOrders);
};

const selectOrder = (event: MouseEvent, orderId: string) => {
  if ((event.target as HTMLElement).tagName === 'A') {
    // It means a link was clicked, so ignore selecting the order here (it will be done in the link href instead).
    // This is required because otherwise, the click event may override the nested anchor element. It will also
    // have two browser history entries.
    return;
  }

  const selectedOrder = route.query.selectedOrder as string || '';

  if (!selectedOrder || selectedOrder != orderId) {
    queryStringStore.update({
      'selectedOrder': orderId,
      'selectedPosition': '',
    });
  } else {
    queryStringStore.update({ 'selectedOrder': '' });
  }
};
</script>

<style lang="scss" scoped></style>
